.activity_list {
  max-width: 100vw;
  position: relative;
  padding-bottom:30vh;
  background-color: #F0F2F5;
}

.activity_list h3 {    
  font-size: 14px;
  font-weight: normal;
}


.activity_list h1 {    
  font-size: 16px;
  line-height: 18px;  
  font-weight: normal;
}

.activity_list section {
  margin-top: 0.1em;
  margin-bottom: 0.005em;
  background-color: #fff;
}

